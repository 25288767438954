import React, { useEffect } from "react";

const ControlHippo = () => {
  useEffect(() => {
    const scriptOptions = {
      "enabled": true,
      "compSeting": {
      "compName": "Mindful Memorials",
      "brandImg": "https://mindfulmemorials.co.uk/static/MindfulMemorials_LogoSlate-25a8b1a35e2dd2f9f9650b0d6dc69135.svg",
      "welText": "Hi! How can we help you today?",
      "msgTxt": "",
      "ctaText": "Speak with us",
      "bgColor": "#21c940",
      "brRadius": "25",
      "defaultShow": true,
      "phoneNumber": "447467606324"
      },
      "chatBtnSetng": {
      "bgColor": "#21c940",
      "brRadius": "25",
      "mgLeft": "10",
      "mgRight": "20",
      "ctaText": "Speak with us",
      "mgBtm": "20",
      "ctaIconCtrlh": false,
      "position": "right"
      }
      };

    const url = 'https://controlhippo.s3.ca-central-1.amazonaws.com/js/ctrlhBtnGnrt.js?38800';
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = url;

    script.onload = () => {
      if (typeof window.GenerateWhatsappChatWidget === "function") {
        window.GenerateWhatsappChatWidget(scriptOptions);
      } else {
        console.error("GenerateWhatsappChatWidget is not available.");
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup script on component unmount
    };
  }, []);

  return null; // No UI, just injects the script
};

export default ControlHippo;